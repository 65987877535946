import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, Link } from "react-router-dom";
import logo1 from "../assets/images/logo_transparent.png";
import logoChristmas from "../assets/logo_christmas.svg";

// import system
import Footer from "../components/Footer";
import JButton from "../components/JButton";
import LanguagePicker from "../components/LanguagePicker";
import { AuthContext } from "../components/ContextProvider";

// import mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { ExitToApp } from "@material-ui/icons";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/DisabledByDefault";

// component
const Navigation = () => {
  //context
  const { auth, setAuth } = React.useContext(AuthContext);

  //state
  const { t } = useTranslation();
  const [linkIndex, setLinkIndex] = React.useState(0);
  const [menuDisplay, setMenuDisplay] = React.useState(false);

  //hande menu button
  const handleMenuButton = () => {
    setMenuDisplay(true);
  };

  //handlee close button
  const handleCloseButton = () => {
    setMenuDisplay(false);
  };

  // scroll handling
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  // handle logout
  const handleLogout = () => {
    setAuth(false);
  };

  // handle link click
  const handleClick = (index) => {
    setLinkIndex(index);
    setMenuDisplay(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // function
  return (
    <>
      <Box className="navigation-top">
        <Container maxWidth="lg">
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <LanguagePicker />
            <Link
              className="link_top"
              to="/contact"
              onClick={() => handleClick(0)}
            >
              {t("navbar.contact")}
            </Link>

            {auth ? (
              <>
                <Link className="link_top" to="/" onClick={handleLogout}>
                  {t("navbar.logout")}
                  <ExitToApp
                    style={{ fontSize: "18px", margin: "0  0 -3px 3px" }}
                  />
                </Link>
              </>
            ) : (
              <Link
                className="link_top"
                to="/login"
                onClick={() => handleClick(0)}
              >
                {t("navbar.login")}
              </Link>
            )}
          </Grid>
        </Container>
      </Box>
      <Box className="navbar sticky">
        <Container maxWidth="lg">
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box className="navbar_box">
              <Link
                className="navbar_link-icon"
                to="/"
                onClick={() => handleClick(0)}
              >
                <img
                  src={logo1}
                  className="App-logo"
                  alt="logo"
                  title="logo"
                  style={{
                    height: scrollPosition < 50 ? "90px" : "60px",
                    transition: "0.5s",
                  }}
                />
              </Link>
            </Box>
            <Box className="navbar_box_mini">
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Link
                  className="navbar_link-icon"
                  to="/"
                  onClick={() => handleClick(0)}
                >
                  <img
                    src={logo1}
                    className="navbar_icon"
                    alt="logo"
                    title="logo"
                  />
                </Link>
                <span className="title">JAS-Vermittlung</span>
                {!menuDisplay ? (
                  <MenuIcon className="menu_icon" onClick={handleMenuButton} />
                ) : (
                  <CloseIcon
                    className="close_icon"
                    onClick={handleCloseButton}
                  />
                )}
              </Grid>
            </Box>
            <Box
              className={`navbar_link_container ${menuDisplay ? "" : "hide"}`}
            >
              <Link
                className={
                  "navbar_link" + (linkIndex === 1 ? " current_link" : "")
                }
                to="/news"
                onClick={() => handleClick(1)}
              >
                {t("navbar.news")}
              </Link>
              <Link
                className={
                  "navbar_link" + (linkIndex === 2 ? " current_link" : "")
                }
                to="/about"
                onClick={() => handleClick(2)}
              >
                {t("navbar.about")}
              </Link>
              <Link
                className={
                  "navbar_link" + (linkIndex === 3 ? " current_link" : "")
                }
                to="/employer"
                onClick={() => handleClick(3)}
              >
                {t("navbar.employer")}
              </Link>
              <Link
                className={
                  "navbar_link" + (linkIndex === 4 ? " current_link" : "")
                }
                to="/students"
                onClick={() => handleClick(4)}
              >
                {t("navbar.students")}
              </Link>
              <Link
                className={
                  "navbar_link" + (linkIndex === 5 ? " current_link" : "")
                }
                to="/shop"
                onClick={() => handleClick(5)}
              >
                {t("navbar.shop")}
              </Link>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <JButton
                type="primary"
                text="navbar.registration"
                to="/registration"
                onClick={() => handleClick(0)}
                className="btn_registration"
              />
              {/* <AccountMenu /> */}
            </Box>
          </Grid>
        </Container>
      </Box>

      <Outlet />

      <Footer />
    </>
  );
};

export default Navigation;
