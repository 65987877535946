import React, { useEffect, useState } from "react";

// import mui
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneIcon from "@mui/icons-material/Phone";
import TelegramIcon from "@mui/icons-material/Telegram";
import EmailIcon from "@mui/icons-material/Email";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";

// component
const SideBox = () => {
  //   const { t } = useTranslation();
  const [country, setCountry] = useState(null);

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await fetch("https://ipapi.co/json/"); // Replace with preferred API
        const data = await response.json();
        setCountry(data.country_name); // or data.country_code for "US", "IN", etc.
      } catch (error) {
        console.error("Error fetching country information:", error);
      }
    };

    fetchCountry();
  }, []);

  return (
    <div>
      {/* {country || "Loading..."} */}
      <Box className="side_box">
        <ul>
          <li>
            <Tooltip title="Telefon" placement="left" arrow>
              <a
                href={
                  country && country === "Germany"
                    ? "tel:+4917643656708"
                    : "tel:+998943327401"
                }
                target="_blank"
                rel="noreferrer"
              >
                <PhoneIcon />
              </a>
            </Tooltip>
          </li>
          <li>
            <Tooltip title="Email" placement="left" arrow>
              <a
                href={
                  country && country === "Germany"
                    ? "mailto:info@jas-vermittlung.de"
                    : "mailto:shahobdn1@gmail.com"
                }
                target="_blank"
                rel="noreferrer"
              >
                <EmailIcon />
              </a>
            </Tooltip>
          </li>
          <li>
            <Tooltip title="WhatsApp" placement="left" arrow>
              <a
                href={
                  country && country === "Germany"
                    ? "https://wa.me/+4917643656708"
                    : "https://wa.me/+998943327401"
                }
                target="_blank"
                rel="noreferrer"
              >
                <WhatsAppIcon />
              </a>
            </Tooltip>
          </li>
          <li>
            <Tooltip title="Telegram" placement="left" arrow>
              <a
                href={
                  country && country === "Germany"
                    ? "https://t.me/+4917643656708"
                    : "https://t.me/+998943327401"
                }
                target="_blank"
                rel="noreferrer"
              >
                <TelegramIcon />
              </a>
            </Tooltip>
          </li>
        </ul>
      </Box>
    </div>
  );
};

export default SideBox;
