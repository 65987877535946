import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Link,
  Container,
} from "@mui/material";

import partner1 from "../assets/partner1.jpg";
import partner2 from "../assets/partner2.jpg";
import partner3 from "../assets/partner3.jpg";
import partner4 from "../assets/partner4.jpg";
import congratulation from "../assets/images/congratulation.jpg";

const Partners = () => {
  const { t } = useTranslation();

  const partners = [
    {
      id: 1,
      title: "Gemüsebau Rapp",
      subtitle: t("partners.title1"),
      image: partner1,
      link: "https://www.rapp-gemuese.de/",
    },
    {
      id: 2,
      title: "AOK",
      subtitle: t("partners.title2"),
      image: partner2,
      link: "https://www.aok.de/pk/",
    },
    {
      id: 3,
      title: "HanseMerkur",
      subtitle: t("partners.title3"),
      image: partner3,
      link: "https://www.hansemerkur.de/",
    },
    {
      id: 4,
      title: "Pegasus Airlines",
      subtitle: t("partners.title4"),
      image: partner4,
      link: "https://www.flypgs.com/en/",
    },
  ];

  return (
    <>
      <Container maxWidth="lg">
        <Box sx={{ py: 4, backgroundColor: "#f9f9f9" }}>
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            sx={{ fontWeight: "bold", color: "#333", mb: 4 }}
          >
            {t("partners.headline")}
          </Typography>
          <Grid container spacing={3} justifyContent="center">
            {partners.map((partner) => (
              <Grid item xs={12} sm={6} md={3} key={partner.id}>
                <Link
                  href={partner.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="none"
                  sx={{ textDecoration: "none" }}
                >
                  <Card
                    sx={{
                      textAlign: "center",
                      boxShadow: 3,
                      "&:hover": {
                        boxShadow: 6,
                        transform: "translateY(-5px)",
                        transition: "0.3s",
                      },
                      borderRadius: "20px",
                    }}
                  >
                    <CardMedia
                      component="img"
                      image={partner.image}
                      alt={partner.title}
                      sx={{ height: 200, objectFit: "cover" }}
                    />
                    <CardContent>
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{ fontWeight: "bold", color: "#222" }}
                      >
                        {partner.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {partner.subtitle}
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
      {/* <img
        src={congratulation}
        alt="congratulation"
        congratulation
        title="congratulation"
        style={{ height: "auto", width: "100%" }}
      /> */}
    </>
  );
};

export default Partners;
