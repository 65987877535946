import "./App.scss";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";
import Services from "./pages/Services";
import About from "./pages/About";
import Employer from "./pages/Employer";
import Navigation from "./pages/Navigation";
import Students from "./pages/Students";
import Imprint from "./pages/Imprint";
import DataProtection from "./pages/DataProtection";
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import RegisteredPeople from "./pages/RegisteredPeople";
import News from "./pages/News";
import Shop from "./pages/Shop";
import Snowfall from "react-snowfall";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Navigation />}>
          <Route index element={<Home />} />
          <Route path="news" element={<News />} />
          <Route path="about" element={<About />} />
          <Route path="services" element={<Services />} />
          <Route path="shop" element={<Shop />} />
          <Route path="contact" element={<Contact />} />
          <Route path="employer" element={<Employer />} />
          <Route path="students" element={<Students />} />
          <Route path="registration" element={<Registration />} />
          <Route path="imprint" element={<Imprint />} />
          <Route path="data-protection" element={<DataProtection />} />
          <Route path="registered" element={<RegisteredPeople />} />
          <Route path="login" element={<Login />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
